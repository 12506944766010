.Splash {
    display: flex;
}

@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .background-image, .fade-image , .logo{
    width: 100%;
    left: 0;
    height: auto;
  }
  .fade-image {
    position: absolute;
    animation: fadeInOut 2.5s infinite; 
  }
  
  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: .9;
    }
}

.logo {
    position: absolute;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  